/* eslint react/jsx-key: off */
import {
  Datagrid,
  Identifier,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

const OrderViewEmbedded = ({ id }: { id?: Identifier }) => (
  <Show title=" " id={id}>
    <SimpleShowLayout>
      <ReferenceField label="Room Number" source="room_id" reference="room">
        <TextField source="number" />
      </ReferenceField>

      <ReferenceManyField label="Items" reference="item" target="order_id">
        <Datagrid>
          <TextField source="name_en" />
          <TextField source="name_de" />
          <NumberField source="quantity" />
          <NumberField source="sale_price" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

export default OrderViewEmbedded;
