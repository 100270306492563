/* eslint react/jsx-key: off */
import { BooleanInput, Create, NumberInput, required, SimpleForm, TextInput } from 'react-admin';

const ProductCreate = () => {
  return (
    <Create redirect="show">
      <SimpleForm>
        <TextInput source="ean" autoFocus validate={[required()]} />
        <TextInput source="name_en" validate={[required()]} />
        <TextInput source="name_de" validate={[required()]} />
        <NumberInput source="price" validate={[required()]} />
        <BooleanInput source="tax_inclusive" defaultChecked />
        <TextInput source="picture" />
      </SimpleForm>
    </Create>
  );
};

export default ProductCreate;
