import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Confirm, Loading, useGetList, useNotify, useRecordContext, useUpdate } from 'react-admin';
import { buttonStyles } from 'src/services/theme';

export const LearnButton = (props: any) => {
  const record: any = useRecordContext(props);
  const [update, { isLoading, error }] = useUpdate();
  const [isShowInstruction, setIsShowInstruction] = useState<boolean>(false);
  const [readerId, setReaderId] = useState<string | null>(null);
  const [isShowConfirmRemove, setIsShowConfirmRemove] = useState<boolean>(false);
  const [selectedReader, setSelectedReader] = useState<any>(null);
  const notify = useNotify();

  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const {
    data: readers,
    isLoading: isLoadingObject,
    error: errorGetObject,
  } = useGetList('object', {
    pagination: { page: 1, perPage: 20 },
    filter: { type: 'NfcCodeTouch' },
  });

  const { data: dispensers } = useGetList('object', {
    pagination: { page: 1, perPage: 1 },
    filter: { type: 'CardDispenser' },
  });

  console.log(record.nfc_ids?.length);

  useEffect(() => {
    if (record.nfc_id && isShowInstruction) {
      // stop learn
      setIsShowInstruction(false);
      update('key', { id: record.id, data: { nfc_update_at: null, reader_id: readerId }, previousData: record });
      notify('NFC learn success', { type: 'success' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record.nfc_id, isShowInstruction]);

  const handleRemoveNfc = async () => {
    update('key', {
      id: record.id,
      data: { nfc_ids: [] },
      previousData: record,
    }).catch(() => {
      notify('NFC remove failed', { type: 'warning' });
    });
  };

  const handleLearn = (reader_id: string, is_on_ncd: boolean) => {
    if (is_on_ncd) {
      update('key', { id: record.id, data: { nfc_update_at: moment().format(), reader_id }, previousData: record });
    } else {
      setReaderId(reader_id);
      update('key', { id: record.id, data: { nfc_update_at: moment().format(), reader_id }, previousData: record });
      setIsShowInstruction(true);
      setTimeLeft(10);
      setTimeout(() => {
        setIsShowInstruction(false);
        onStopLearn();
      }, 10000);
    }
  };

  const onStopLearn = () => {
    notify('NFC stop learn', { type: 'info' });
    update('key', { id: record.id, data: { nfc_update_at: null, reader_id: readerId }, previousData: record });
    setIsShowInstruction(false);
  };

  if (isLoadingObject) {
    return <Loading />;
  }
  if (error || errorGetObject) {
    return <p>ERROR</p>;
  }
  return record ? (
    <>
      <Box>
        {readers?.flatMap((reader) => (
          <Button
            size="small"
            variant="contained"
            sx={buttonStyles.info}
            key={reader.id}
            disabled={isLoading}
            onClick={() => {
              if (record.nfc_ids?.length > 0) {
                setIsShowConfirmRemove(true);
                setSelectedReader(reader);
              } else {
                handleLearn(reader.id, reader.is_on_ncd);
              }
            }}
          >
            {reader.name}
          </Button>
        ))}
        {(record.nfc_ids?.length == 0 || record.nfc_ids?.length === undefined) &&
          dispensers?.flatMap((reader) => (
            <Button
              size="small"
              variant="contained"
              sx={buttonStyles.info}
              key={reader.id}
              disabled={isLoading}
              onClick={() => {
                handleLearn(reader.id, true);
              }}
            >
              {reader.name}
            </Button>
          ))}
        <Dialog open={isShowInstruction} onClose={onStopLearn}>
          <DialogTitle>Learn NFC Tag</DialogTitle>
          <DialogContent sx={{ minWidth: 300 }}>
            <img
              src="learnNFC.png"
              alt="learnNFC"
              width="100%"
              height="100%"
              style={{ objectFit: 'contain', marginBottom: '10px' }}
            />

            <DialogContentText style={{ marginBottom: 35 }}>
              {`Hold the NFC tag at the location shown on your nfc reader (${timeLeft}s)`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button sx={buttonStyles.warning} onClick={onStopLearn}>
              Stop
            </Button>
          </DialogActions>
        </Dialog>
        <Confirm
          isOpen={isShowConfirmRemove}
          loading={isLoading}
          title="Learn NFC"
          cancel="Learn new one"
          confirm="Delete and learn"
          content="Do you want to delete existing NFC tag and learn new one?"
          onClose={() => {
            setIsShowConfirmRemove(false);
            handleLearn(selectedReader.id, false);
          }}
          onConfirm={() => {
            setIsShowConfirmRemove(false);
            handleRemoveNfc();
            handleLearn(selectedReader.id, false);
          }}
        />
      </Box>
    </>
  ) : null;
};

LearnButton.defaultProps = { label: 'Learn' };
