import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { Confirm, TextField, useRecordContext, useUpdate } from 'react-admin';
import { buttonStyles } from 'src/services/theme';

export const CodeView = (props: any) => {
  const record = useRecordContext(props);
  const [update, { isLoading, error }] = useUpdate();
  const [isShowConfirm, setIsShowConfirm] = useState<boolean>(false);
  const env = process.env.NODE_ENV;

  const handleRemove = () => {
    update('key', { id: record.id, data: { code: '' }, previousData: record }).then(() => {
      setIsShowConfirm(false);
    });
  };

  const handleNewCodeClick = () => {
    const code = Math.floor(100000 + Math.random() * 900000).toString();
    update('key', { id: record.id, data: { code: code }, previousData: record });
  };

  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {env !== 'production' && <TextField source="code" emptyText="No Code" />}
      {record.code && record.code !== '' ? (
        <Button
          size="small"
          variant="contained"
          sx={buttonStyles.warning}
          disabled={isLoading}
          onClick={() => setIsShowConfirm(true)}
        >
          Remove
        </Button>
      ) : null}
      <Button size="small" variant="contained" sx={buttonStyles.info} disabled={isLoading} onClick={handleNewCodeClick}>
        New Code
      </Button>
      <Confirm
        isOpen={isShowConfirm}
        loading={isLoading}
        title="Remove code"
        content="Are you sure you want to remove the code?"
        onConfirm={handleRemove}
        onClose={() => setIsShowConfirm(false)}
      />
    </Box>
  );
};

CodeView.defaultProps = { label: 'Code' };
