/* eslint react/jsx-key: off */
import { Datagrid, List, TextField } from 'react-admin';

const ControlList = () => {
  return (
    <List queryOptions={{ refetchInterval: 5000 }} sort={{ field: 'name', order: 'DESC' }}>
      <Datagrid optimized>
        <TextField source="id" />
        <TextField source="type" />
        <TextField source="name" />
      </Datagrid>
    </List>
  );
};

export default ControlList;
