import { Identifier, Show, SimpleShowLayout, TextField, usePermissions } from 'react-admin';
import { Role_Enum } from 'src/utils/enum';
import { BlcIdField } from './BlcIdField';
import { CodeView } from './CodeView';
import { DeleteGuestButton } from './DeleteGuestButton';
import { FoundInLoxoneView } from './FoundInLoxoneView';
import { GroupButton } from './GroupButton';
import { LearnButton } from './LearnButton';
import { LogView } from './LogView';
import { NFCField } from './NFCField';
import { SendMail } from './SendMail';

const KeyViewEmbedded = ({ id }: { id?: Identifier }) => {
  const { permissions } = usePermissions();
  return (
    <Show
      title=" "
      id={id}
      queryOptions={{ refetchInterval: 5000 }}
      sx={{
        'p > span': {
          fontSize: '14px',
        },
      }}
    >
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="guest_id" />
        {permissions === Role_Enum.Master && <TextField source="reservation_id" />}
        <TextField source="confirmation_number" />
        <TextField source="email" emptyText="No Email Address" />
        <TextField source="phone" emptyText="No Phone Number" />
        <BlcIdField source="blc_id" />
        {(permissions === Role_Enum.Master || permissions === Role_Enum.Superuser) && <FoundInLoxoneView />}

        <CodeView className="codeView" />
        <SendMail />
        <LearnButton />
        <NFCField source="nfc_ids" label="NFC TAG" emptyText="No Tags" />
        {/* <RemoveNfcButton /> */}
        <GroupButton />
        {permissions === Role_Enum.Master && <DeleteGuestButton />}
        {permissions === Role_Enum.Master && <LogView />}
      </SimpleShowLayout>
    </Show>
  );
};

export default KeyViewEmbedded;
